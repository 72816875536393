import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { BrowserModule,  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { WizardComponent } from './module/digitalizar/wizard/wizard.component';
import { IncluirDocumentosComponent } from './module/digitalizar/wizard/incluir-documentos/incluir-documentos.component';
import { ClassificarDocumentosComponent } from './module/digitalizar/wizard/classificar-documentos/classificar-documentos.component';


registerLocaleData(pt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
    ],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/documentos/pendentes-digitalizacao',
        pathMatch: 'full'
    },
    {
        path: 'documentos',
        loadChildren: () => import('../../module/caixa-entrada/caixa-entrada.module').then(m => m.CaixaEntradaModule)
    },
    {
        path: 'digitalizar',
        loadChildren: () => import('../../module/digitalizar/digitalizar.module').then(m => m.DigitalizarModule)
    },
    {
        path: 'validar',
        loadChildren: () => import('../../module/validar/validar.module').then(m => m.ValidarModule)
    }
];
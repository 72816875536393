import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/',
        title: 'Caixa de Entrada',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    }/*,
    {
        path: '/documentos-pendentes-digitalizacao',
        title: 'Documentos Pendentes de Digitalização',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    },
    {
        path: '/documentos-pendentes-validacao',
        title: 'Documentos Pendentes de Validação',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    }*/
]    